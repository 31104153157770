<script>
export default {
  name: "Billing",
  props: ["spaces"],
};
</script>

<template>
  <div id="Billing" class="container-fluid">
    <div class="row">
      <h3 class="col">Billing</h3>
    </div>

    <ul class="nav nav-tabs">
      <li class="nav-item">
        <router-link :to="{ name: 'BillingPaid' }" class="nav-link"
          >Paid</router-link
        >
      </li>

      <li class="nav-item">
        <router-link :to="{ name: 'BillingUnpaid' }" class="nav-link"
          >Unpaid</router-link
        >
      </li>
    </ul>

    <div class="row">
      <router-view :spaces="spaces" />
    </div>
  </div>
</template>

<style lang="scss">
#Billing {
  #FilterButton {
    font-size: 15px;
  }
  padding-top: 15px;
  background: white;
  #nopay {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
  }
  .table {
    background: white;
    border: 1px solid #ccc;
    border-radius: 1px;
    margin-bottom: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .router-link-active {
    color: #495057;
    background-color: #fff;
    border-color: #ddd #ddd #fff;
  }
  .nav {
    margin-top: 10px;
  }
  table.table,
  button {
    font-size: 13px;
  }
  table.table td {
    padding: 0.4rem;
  }
}
</style>
